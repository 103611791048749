<script setup lang="ts">
import { createGtm } from '@gtm-support/vue-gtm'

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#FFF',
    },
    { name: 'viewport', content: 'width=device-width, maximum-scale=1, user-scalable=0, initial-scale=1' },
  ],
})

const { cookiesEnabledIds } = useCookieControl()
const nuxtApp = useNuxtApp()

// example: react to a cookie being accepted
watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    // Check if the `google-tag-manager` cookie got added
    const gtmAdded = !previous?.includes('google-tag-manager') && current?.includes('google-tag-manager')

    if (gtmAdded) {
      // The `google-tag-manager` cookie got added
      nuxtApp.vueApp.use(
        createGtm({
          id: 'GTM-TNXS9GK',
          defer: false,
          compatibility: false,
          enabled: true,
          debug: false,
          vueRouter: useRouter(),
          trackOnNextTick: false,
        }),
      )
    }

    // Check if the `facebook-pixel` cookie got added
    const fbPixelAdded = !previous?.includes('facebook-pixel') && current?.includes('facebook-pixel')

    if (fbPixelAdded) {
      // The `facebook-pixel` cookie got added
      nuxtApp.$fb.enable()
    }
  },
  { deep: true },
)
</script>

<template>
  <div>
    

    <NuxtLayout>
      <CookieControl locale="da" />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
