import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/packages/kassebil/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}