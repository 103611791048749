import revive_payload_client_oQRalS740B from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9AtxyZHgNi from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_32wdhf3MPD from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dlMOxbIyP8 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.3.5_vue@3.4.35/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_qnGP3a33xy from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sxp3haBM2B from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_iSWnKxsDAS from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_suifOaSAoA from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_R2NdOJy3mX from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/packages/kassebil/.nuxt/components.plugin.mjs";
import prefetch_client_88iKNdoR1G from "/app/node_modules/.pnpm/nuxt@3.12.4_@planetscale+database@1.18.0_@types+node@20.12.2_drizzle-orm@0.29.5_eslint@8.57.0_xjhr4bftuunm3e2vmhgvkexlna/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_9apgbVY9A7 from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.12.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_2dw39lmoTH from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.12.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_Oip0DQqTyf from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_vite@5.3.5_vue@3.4.35/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_Ey0VEHpkwe from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_vite@5.3.5_vue@3.4.35/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import slideovers_qoFoUV2C1Q from "/app/node_modules/.pnpm/@nuxt+ui@2.18.3_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_GQCOGc7BoF from "/app/node_modules/.pnpm/@nuxt+ui@2.18.3_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_FSnatAV3M6 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.3_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_zhpI4vp1Fk from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_u68AO5BQ1v from "/app/node_modules/.pnpm/@nuxt+icon@1.4.4_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_pBJ2GJjmVQ from "/app/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_Py35AOPnIw from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@7.5.1_webpack@5.93.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_client_n0PsDSU0XJ from "/app/node_modules/.pnpm/@nuxtjs+plausible@0.2.4/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import presets_63ef8CZfwK from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_JcICGtEwQ0 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_RyrlCrhHQK from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import error_handler_kEP5FliEXj from "/app/packages/kassebil/plugins/error-handler.ts";
import facebook_pixel_client_DZvV2r4W6e from "/app/packages/kassebil/plugins/facebook-pixel.client.ts";
import posthog_client_fUucxKWhZE from "/app/packages/kassebil/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/app/packages/kassebil/plugins/sentry.client.ts";
import vue_gtm_client_stBjLl0OeM from "/app/packages/kassebil/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_oQRalS740B,
  unhead_9AtxyZHgNi,
  router_32wdhf3MPD,
  _0_siteConfig_dlMOxbIyP8,
  payload_client_qnGP3a33xy,
  navigation_repaint_client_sxp3haBM2B,
  check_outdated_build_client_iSWnKxsDAS,
  view_transitions_client_suifOaSAoA,
  chunk_reload_client_R2NdOJy3mX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_88iKNdoR1G,
  titles_9apgbVY9A7,
  defaults_2dw39lmoTH,
  siteConfig_Oip0DQqTyf,
  inferSeoMetaPlugin_Ey0VEHpkwe,
  slideovers_qoFoUV2C1Q,
  modals_GQCOGc7BoF,
  colors_FSnatAV3M6,
  plugin_client_zhpI4vp1Fk,
  plugin_u68AO5BQ1v,
  plugin_pBJ2GJjmVQ,
  plugin_Py35AOPnIw,
  plugin_client_n0PsDSU0XJ,
  presets_63ef8CZfwK,
  scrollbars_client_JcICGtEwQ0,
  variables_RyrlCrhHQK,
  error_handler_kEP5FliEXj,
  facebook_pixel_client_DZvV2r4W6e,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk,
  vue_gtm_client_stBjLl0OeM
]