import { useRoute } from '#app'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  if ((import.meta.client || import.meta.browser) && runtimeConfig.public.isProd) {
    try {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq)
          return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq)
          f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      )

      const route = useRoute()
      fbq('init', '367147641208591')

      watch(route, (newVal) => {
        fbq('track', 'PageView')

        if (newVal.fullPath.includes('/tak'))
          fbq('track', 'Lead')
      })
    }
    catch (e) {
      console.error(e)
    }
  }
})
