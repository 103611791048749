import { default as _91slug_93iLiAOdUuaFMeta } from "/app/packages/kassebil/pages/blog/[slug].vue?macro=true";
import { default as indexZZ0bSP6Hm3Meta } from "/app/packages/kassebil/pages/blog/index.vue?macro=true";
import { default as indexsMzhq3DNQFMeta } from "/app/packages/kassebil/pages/brugte-varebiler/[brand]/[model]/[id]/index.vue?macro=true";
import { default as indexHKc0d3RmB3Meta } from "/app/packages/kassebil/pages/brugte-varebiler/[brand]/[model]/index.vue?macro=true";
import { default as indexFE4UY2YqGJMeta } from "/app/packages/kassebil/pages/brugte-varebiler/[brand]/index.vue?macro=true";
import { default as indexX2j6FdoSuYMeta } from "/app/packages/kassebil/pages/brugte-varebiler/index.vue?macro=true";
import { default as faqG6n7psiuk4Meta } from "/app/packages/base/pages/faq.vue?macro=true";
import { default as indexYXGOy2Ycr5Meta } from "/app/packages/kassebil/pages/index.vue?macro=true";
import { default as karrierer15yZsrCOSMeta } from "/app/packages/base/pages/karriere.vue?macro=true";
import { default as indexwjwakojRuSMeta } from "/app/packages/kassebil/pages/nye-varebiler/[brand]/[model]/index.vue?macro=true";
import { default as indextP12iSEWU5Meta } from "/app/packages/kassebil/pages/nye-varebiler/[brand]/index.vue?macro=true";
import { default as indexGR3zCANmrfMeta } from "/app/packages/kassebil/pages/nye-varebiler/index.vue?macro=true";
import { default as om_45osaDgQ9KlDcXMeta } from "/app/packages/base/pages/om-os.vue?macro=true";
import { default as privatlivspolitiksVzSDO8MgyMeta } from "/app/packages/base/pages/privatlivspolitik.vue?macro=true";
import { default as taksg9Cx9hMAFMeta } from "/app/packages/kassebil/pages/tak.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/app/packages/kassebil/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/packages/kassebil/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "brugte-varebiler-brand-model-id",
    path: "/brugte-varebiler/:brand()/:model()/:id()",
    meta: indexsMzhq3DNQFMeta || {},
    component: () => import("/app/packages/kassebil/pages/brugte-varebiler/[brand]/[model]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "brugte-varebiler-brand-model",
    path: "/brugte-varebiler/:brand()/:model()",
    component: () => import("/app/packages/kassebil/pages/brugte-varebiler/[brand]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "brugte-varebiler-brand",
    path: "/brugte-varebiler/:brand()",
    meta: indexFE4UY2YqGJMeta || {},
    component: () => import("/app/packages/kassebil/pages/brugte-varebiler/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: "brugte-varebiler",
    path: "/brugte-varebiler",
    component: () => import("/app/packages/kassebil/pages/brugte-varebiler/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/packages/base/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/packages/kassebil/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "karriere",
    path: "/karriere",
    component: () => import("/app/packages/base/pages/karriere.vue").then(m => m.default || m)
  },
  {
    name: "nye-varebiler-brand-model",
    path: "/nye-varebiler/:brand()/:model()",
    meta: indexwjwakojRuSMeta || {},
    component: () => import("/app/packages/kassebil/pages/nye-varebiler/[brand]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "nye-varebiler-brand",
    path: "/nye-varebiler/:brand()",
    component: () => import("/app/packages/kassebil/pages/nye-varebiler/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: "nye-varebiler",
    path: "/nye-varebiler",
    component: () => import("/app/packages/kassebil/pages/nye-varebiler/index.vue").then(m => m.default || m)
  },
  {
    name: "om-os",
    path: "/om-os",
    component: () => import("/app/packages/base/pages/om-os.vue").then(m => m.default || m)
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    component: () => import("/app/packages/base/pages/privatlivspolitik.vue").then(m => m.default || m)
  },
  {
    name: "tak",
    path: "/tak",
    component: () => import("/app/packages/kassebil/pages/tak.vue").then(m => m.default || m)
  }
]