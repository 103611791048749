export default defineAppConfig({
  myLayer: {
    name: 'base' as 'base' | 'hellocar' | 'kassebil' | 'operator'
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: 'base' | 'hellocar' | 'kassebil' | 'operator'
    }
  }
}
