import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": true,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [],
    "optional": [
      {
        "description": {
          "da": "Hjælper os med at forstå brugeradfærd og optimere vores tjenester."
        },
        "name": "Google Tag Manager",
        "targetCookieIds": [
          "_gcl_au",
          "_ga",
          "_gid"
        ]
      },
      {
        "description": {
          "da": "Anvendes til at levere målrettede annoncer via Facebook Pixel."
        },
        "name": "Facebook Pixel",
        "targetCookieIds": [
          "_fbp"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "da",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "da": {
      "accept": "Accepter",
      "acceptAll": "Accepter alle",
      "bannerDescription": "Vi bruger vores egne cookies og tredjepartscookies, så vi kan vise dig denne hjemmeside og bedre forstå hvordan du bruger den, med henblik på at forbedre de tjenester som vi tilbyder. Du kan til enhver tid trække dit samtykke vedr. brug af cookies tilbage. Dette gøres ved hjælp af cookie-knappen, der vises når et valg er foretaget på dette banner.",
      "bannerTitle": "Cookies",
      "close": "Luk",
      "cookiesFunctional": "Funktionelle cookies",
      "cookiesNecessary": "Nødvendige cookies",
      "cookiesOptional": "Valgfrie cookies",
      "decline": "Afvis",
      "declineAll": "Afvis alle",
      "here": "Her",
      "iframeBlocked": "For at se dette skal du aktivere funktionelle cookies",
      "manageCookies": "Læs mere og tilpas",
      "save": "Gem",
      "settingsUnsaved": "Du har indstillinger, der ikke er gemt"
    }
  }
} as ModuleOptions